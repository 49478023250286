<template>
    <HideWhileSpinnerActive>
        <template v-if="matches.length !== 0">
            <div class="pt-10">
                <h1 class="text-white font-semibold text-2xl px-2 whitespace-pre-line" v-text="t('your_games')" />
            </div>
            <div class="flex flex-wrap">
                <router-link v-for="match in matches" :key="match.id" :to="{ name: 'match.show', params: {id: match.id}}" class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 px-3 py-3">
                    <MatchItem  :match="match" />
                </router-link>
            </div>
            <div class="fixed bottom-0 inset-x-0 mt-6 px-2 mb-24 md:mb-10">
                <div class="mt-6 md:max-w-xl m-auto">
                    <router-link :to="{name: 'match.create'}">
                        <span class="block w-full rounded-md shadow-sm px-3">
                            <button type="button" class="button w-full">
                                {{ t('upload_new_game') }}
                            </button>
                        </span>
                    </router-link>
                </div>
            </div>
        </template>
        <template v-else>
            <!-- Empty state -->
            <div class="md:max-w-xl m-auto">
                <img class="object-scale-down h-64 w-full" src="@/assets/images/Screenshot_from_2020-10-13_14-30-38.png" alt="">
                <div class="text-white text-center">
                    <h3 class="heading-1 whitespace-pre-line" v-text ="t('no_games_uploaded_yet')" />
                    <p class="text-sm px-6 py-6 md:px-24">{{ t('upload_game_and_receive_review') }}</p>
                </div>
                <div class="mt-6">
                    <router-link :to="{name: 'match.create.2'}">
                        <span class="block w-full rounded-md shadow-sm">
                            <button type="button" class="button w-full">
                                {{ t('upload_first_game') }}
                            </button>
                        </span>
                    </router-link>
                </div>
            </div>
        </template>
    </HideWhileSpinnerActive>
</template>

<script>
import MatchItem from "@/components/match/IndexItem";
import HideWhileSpinnerActive from "@/wrappers/HideWhileSpinnerActive";
import { indexMatch } from "@/helpers/api/MatchHelper";
import { startSpinner, stopSpinner } from "@/helpers/storage/loadingSpinnerHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "MatchIndex",
    components: { MatchItem, HideWhileSpinnerActive},
    setup() {
        return useI18n({
            viewPrefix: "MatchIndex"
        });
    },
    created(){
        startSpinner()
        indexMatch()
            .then(({ data }) => {
                this.matches = data;

                const sortByTimeDesc = (a, b) => (new Date(b.created_at)).getTime() - (new Date(a.created_at)).getTime();
                this.matches.sort(sortByTimeDesc);
            })
            .finally(()=> stopSpinner());
    },
    data(){
        return {
            matches: [],
        }
    },
}
</script>